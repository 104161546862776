<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue';
import {
	CheckboxIndicator,
	CheckboxRoot,
	useForwardPropsEmits,
} from 'radix-vue';
import { PhCheck } from '@phosphor-icons/vue';
import { cn } from '../../../lib/utils';

const props = defineProps<
	CheckboxRootProps & { class?: HTMLAttributes['class'] }
>();
const emits = defineEmits<CheckboxRootEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<CheckboxRoot
		v-bind="forwarded"
		:class="
			cn(
				'h-5xl w-5xl p-[3px] rounded-small border border-gray-300 data-[state=checked]:border-gray-700 ring-offset-black disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-gray-700 data-[state=checked]:text-white bg-white',
				props.class,
			)
		"
	>
		<CheckboxIndicator
			class="flex items-center justify-center w-full h-full text-current"
		>
			<slot>
				<PhCheck weight="bold" class="h-2xl w-2xl" />
			</slot>
		</CheckboxIndicator>
	</CheckboxRoot>
</template>
